html { font-size: 62.5%; } 

body {
  font-family: 'Open Sans', sans-serif;
  padding: 8.5rem 0 5rem 0;
  font-weight: 400;
  color: #222;
  font-size: 1.3rem;
}

@media (min-width: 768px) {  /* > sm */
  body {
    padding: 10rem 0 5rem 0;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 300;
  color: #888;
}
h1, .h1 {
  font-size: 2.7rem !important;
}
h2, .h2 {
  font-size: 2.4rem !important;
}
h3, .h3 {
  font-size: 2.1rem !important;
}
h4, .h4 {
  font-size: 1.8rem !important;
}
h5, .h5 {
  font-size: 1.6rem !important;
}
h6, .h6 {
  font-size: 1.4rem !important;
}

a {
  color: rgb(41,82,108);
  font-weight: 700;
}
a:hover {
  color: rgb(41,82,108);
  text-decoration: underline;
}

.form-control {
  font-size: 1.3rem;
}

/* input.form-control.h6 {
  font-size: 1.0em;
  font-weight: 600;
} */
input[type=file] {
  width: 100%;
}

.btn-minml, .btn-minml:hover {
  margin: 0;
  color: white;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
}

.btn-minml, .btn-minml:hover:disabled {
  background: linear-gradient(180deg, rgb(78,156,204) 0%, rgb(68,136,179) 100%);
  border-color: rgb(68,136,179);
}
.btn-minml:hover {
  background: linear-gradient(180deg, rgb(73,146,191) 0%, rgb(63,126,166) 100%);
  border-color: rgb(63,126,166);
}
.btn-minml:active {
  background: linear-gradient(180deg, rgb(68,136,179) 0%, rgb(58,117,153) 100%);
  border-color: rgb(58,117,153);
}
.btn-minml.btn-secondary, .btn-minml.btn-secondary:hover:disabled {
  background: linear-gradient(180deg, rgb(201,201,201) 0%, rgb(179,179,179) 100%);
  border-color: rgb(179,179,179);
}
.btn-minml.btn-secondary:hover {
  background: linear-gradient(180deg, rgb(191,191,191) 0%, rgb(166,166,166) 100%);
  border-color: rgb(166,166,166);
}
.btn-minml.btn-secondary:active {
  background: linear-gradient(180deg, rgb(179,179,179) 0%, rgb(153,153,153) 100%);
  border-color: rgb(153,153,153);
}
.btn-minml.btn-danger, .btn-minml.btn-danger:hover:disabled {
  background: linear-gradient(180deg, rgb(204,67,74) 0%, rgb(179,59,65) 100%);
  border-color: rgb(179,59,65);
}
.btn-minml.btn-danger:hover {
  background: linear-gradient(180deg, rgb(191,63,70) 0%, rgb(166,65,60) 100%);
  border-color: rgb(166, 65, 60);
}
.btn-minml.btn-danger:active {
  background: linear-gradient(180deg, rgb(179,59,65) 0%, rgb(153,50,56) 100%);
  border-color: rgb(153, 50, 56);
}

/* .btn-minml:hover:enabled, .btn-minml-secondary:hover:enabled {
  color: #ddd;
} */

/* .input-minml {
  font-size: 1em;
} */

form {
  margin-bottom: 1.5rem;
}

.form-control {
  height: auto !important;
}

.carousel-control-prev-icon {
  opacity: 1.0 !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke-width='0.5' stroke='%23666' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  opacity: 1.0 !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke-width='0.5' stroke='%23666' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
 }

 .card:hover img.closeButton {
  display: block;
}
img.closeButton {
  display: none;
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: -1rem;
  top: -1rem;
  z-index: 5;
}
img.card-img-top {
  height: 150px; 
  width: 100%; 
  object-fit: cover; 
}
img.new {
  opacity: 0.6;
}
