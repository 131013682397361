.nav-top {
  padding: 0;
  min-height: 70px;
  background: linear-gradient(180deg, rgba(65,65,65,1) 0%, rgba(0,0,0,1) 100%);
}

.navbar-brand, .navbar-toggler {
  height: 70px;
  padding-left: 1rem;
  padding-bottom: .5rem;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: lowercase;
  display: flex;
  align-items: flex-end;
}
.navbar-brand a {
  color: white;
}
/* .navbar-brand a:hover {
} */
.navbar-brand a:hover {
  color: white;
  text-decoration: none;
}
.navbar-dark .navbar-toggler {
  border: 0;
  color: rgb(255, 255, 255, 1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1.0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-nav .nav-item, .navbar-dark .navbar-nav .nav-item:hover {
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  color: rgb(255, 255, 255, 1);
  text-decoration: none;
}

.navbar-nav:first-child {
  border-top: 1px solid white;
}

.nav-item {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}
.placeholder .nav-item {
  opacity: 0.5;
}

@media (min-width: 768px) {  /* > sm */
  .navbar-brand {
    padding-left: 0;
  }
  .navbar-nav:first-child {
    border: none;
  }
  .navbar-dark .navbar-nav .nav-item, .navbar-dark .navbar-nav .nav-item:hover {
    padding-left: 4rem;
  }  
  .nav-item {
    height: 70px;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.nav-item:hover, .nav-item.active {
  background: linear-gradient(180deg, rgba(70,140,185,1) 0%, rgba(41,82,108,1) 100%);
}

