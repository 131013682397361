
.big-loader {
  text-align: center;
  font-size: 30vw;
}
.big-loader i {
  color: #eee;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}
