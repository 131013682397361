
.nav-bottom {
  background-color: #142a37 !important;
  border-top: 0.5em solid #468cb9;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 1.1rem;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
}


div.footer {
  height: 3.5rem;
  padding: 1rem 1rem;
}

@media (min-width: 768px) {  /* > sm */

  div.footer {
    padding: 1rem 0;
  }
  
}