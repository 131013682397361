
.big-loader {
  text-align: center;
  font-size: 30vw;
}
.big-loader i {
  color: #eee;
  -webkit-animation: spin 2s infinite linear;
          animation: spin 2s infinite linear;
}

@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

.nav-top {
  padding: 0;
  min-height: 70px;
  background: -webkit-linear-gradient(top, rgba(65,65,65,1) 0%, rgba(0,0,0,1) 100%);
  background: linear-gradient(180deg, rgba(65,65,65,1) 0%, rgba(0,0,0,1) 100%);
}

.navbar-brand, .navbar-toggler {
  height: 70px;
  padding-left: 1rem;
  padding-bottom: .5rem;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: lowercase;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.navbar-brand a {
  color: white;
}
/* .navbar-brand a:hover {
} */
.navbar-brand a:hover {
  color: white;
  text-decoration: none;
}
.navbar-dark .navbar-toggler {
  border: 0;
  color: rgb(255, 255, 255, 1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1.0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-nav .nav-item, .navbar-dark .navbar-nav .nav-item:hover {
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  color: rgb(255, 255, 255, 1);
  text-decoration: none;
}

.navbar-nav:first-child {
  border-top: 1px solid white;
}

.nav-item {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}
.placeholder .nav-item {
  opacity: 0.5;
}

@media (min-width: 768px) {  /* > sm */
  .navbar-brand {
    padding-left: 0;
  }
  .navbar-nav:first-child {
    border: none;
  }
  .navbar-dark .navbar-nav .nav-item, .navbar-dark .navbar-nav .nav-item:hover {
    padding-left: 4rem;
  }  
  .nav-item {
    height: 70px;
    display: -webkit-flex;
    display: flex;
    text-align: right;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }
}
.nav-item:hover, .nav-item.active {
  background: -webkit-linear-gradient(top, rgba(70,140,185,1) 0%, rgba(41,82,108,1) 100%);
  background: linear-gradient(180deg, rgba(70,140,185,1) 0%, rgba(41,82,108,1) 100%);
}



.nav-bottom {
  background-color: #142a37 !important;
  border-top: 0.5em solid #468cb9;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 1.1rem;
  box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
}


div.footer {
  height: 3.5rem;
  padding: 1rem 1rem;
}

@media (min-width: 768px) {  /* > sm */

  div.footer {
    padding: 1rem 0;
  }
  
}
html { font-size: 62.5%; } 

body {
  font-family: 'Open Sans', sans-serif;
  padding: 8.5rem 0 5rem 0;
  font-weight: 400;
  color: #222;
  font-size: 1.3rem;
}

@media (min-width: 768px) {  /* > sm */
  body {
    padding: 10rem 0 5rem 0;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 300;
  color: #888;
}
h1, .h1 {
  font-size: 2.7rem !important;
}
h2, .h2 {
  font-size: 2.4rem !important;
}
h3, .h3 {
  font-size: 2.1rem !important;
}
h4, .h4 {
  font-size: 1.8rem !important;
}
h5, .h5 {
  font-size: 1.6rem !important;
}
h6, .h6 {
  font-size: 1.4rem !important;
}

a {
  color: rgb(41,82,108);
  font-weight: 700;
}
a:hover {
  color: rgb(41,82,108);
  text-decoration: underline;
}

.form-control {
  font-size: 1.3rem;
}

/* input.form-control.h6 {
  font-size: 1.0em;
  font-weight: 600;
} */
input[type=file] {
  width: 100%;
}

.btn-minml, .btn-minml:hover {
  margin: 0;
  color: white;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
}

.btn-minml, .btn-minml:hover:disabled {
  background: -webkit-linear-gradient(top, rgb(78,156,204) 0%, rgb(68,136,179) 100%);
  background: linear-gradient(180deg, rgb(78,156,204) 0%, rgb(68,136,179) 100%);
  border-color: rgb(68,136,179);
}
.btn-minml:hover {
  background: -webkit-linear-gradient(top, rgb(73,146,191) 0%, rgb(63,126,166) 100%);
  background: linear-gradient(180deg, rgb(73,146,191) 0%, rgb(63,126,166) 100%);
  border-color: rgb(63,126,166);
}
.btn-minml:active {
  background: -webkit-linear-gradient(top, rgb(68,136,179) 0%, rgb(58,117,153) 100%);
  background: linear-gradient(180deg, rgb(68,136,179) 0%, rgb(58,117,153) 100%);
  border-color: rgb(58,117,153);
}
.btn-minml.btn-secondary, .btn-minml.btn-secondary:hover:disabled {
  background: -webkit-linear-gradient(top, rgb(201,201,201) 0%, rgb(179,179,179) 100%);
  background: linear-gradient(180deg, rgb(201,201,201) 0%, rgb(179,179,179) 100%);
  border-color: rgb(179,179,179);
}
.btn-minml.btn-secondary:hover {
  background: -webkit-linear-gradient(top, rgb(191,191,191) 0%, rgb(166,166,166) 100%);
  background: linear-gradient(180deg, rgb(191,191,191) 0%, rgb(166,166,166) 100%);
  border-color: rgb(166,166,166);
}
.btn-minml.btn-secondary:active {
  background: -webkit-linear-gradient(top, rgb(179,179,179) 0%, rgb(153,153,153) 100%);
  background: linear-gradient(180deg, rgb(179,179,179) 0%, rgb(153,153,153) 100%);
  border-color: rgb(153,153,153);
}
.btn-minml.btn-danger, .btn-minml.btn-danger:hover:disabled {
  background: -webkit-linear-gradient(top, rgb(204,67,74) 0%, rgb(179,59,65) 100%);
  background: linear-gradient(180deg, rgb(204,67,74) 0%, rgb(179,59,65) 100%);
  border-color: rgb(179,59,65);
}
.btn-minml.btn-danger:hover {
  background: -webkit-linear-gradient(top, rgb(191,63,70) 0%, rgb(166,65,60) 100%);
  background: linear-gradient(180deg, rgb(191,63,70) 0%, rgb(166,65,60) 100%);
  border-color: rgb(166, 65, 60);
}
.btn-minml.btn-danger:active {
  background: -webkit-linear-gradient(top, rgb(179,59,65) 0%, rgb(153,50,56) 100%);
  background: linear-gradient(180deg, rgb(179,59,65) 0%, rgb(153,50,56) 100%);
  border-color: rgb(153, 50, 56);
}

/* .btn-minml:hover:enabled, .btn-minml-secondary:hover:enabled {
  color: #ddd;
} */

/* .input-minml {
  font-size: 1em;
} */

form {
  margin-bottom: 1.5rem;
}

.form-control {
  height: auto !important;
}

.carousel-control-prev-icon {
  opacity: 1.0 !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke-width='0.5' stroke='%23666' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  opacity: 1.0 !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke-width='0.5' stroke='%23666' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
 }

 .card:hover img.closeButton {
  display: block;
}
img.closeButton {
  display: none;
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: -1rem;
  top: -1rem;
  z-index: 5;
}
img.card-img-top {
  height: 150px; 
  width: 100%; 
  object-fit: cover; 
}
img.new {
  opacity: 0.6;
}

